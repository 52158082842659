import * as constant from './constants';

export const initialState = {
  banner: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.EXP_LOAD_BANNER_SUCCESS:
      return {
        ...state,
        banner: action.payload,
      };
    case constant.EXP_LOAD_BANNER_FAILED:
      return {
        ...state,
        banner: [],
      };

    default:
      return state;
  }
};
export default reducer;
