import { coreApi } from 'api/coreApi';
import { snapmartApi } from 'api/snapmartApi';
import { mgtApi } from 'api/mgtApi';
import { s3Api } from 'api/s3Api';

import { fakeMgtBannerApi } from 'api/banner/fakeMgtBannerApi';
import listener from './listener';
import 'containers/Modals/slices/renewMaya';
import 'containers/Home/slices/nps/npsListener';
import 'containers/Account/listener';
import checkoutListenerMiddleware from 'containers/Checkout/rtk/checkout/checkoutListener';
import rebateListenerMiddleware from 'containers/Account/rtk/rebate/rebateListener';

export const middlewares = [
  coreApi.middleware,
  snapmartApi.middleware,
  mgtApi.middleware,
  s3Api.middleware,
  listener.middleware,
  checkoutListenerMiddleware.middleware,
  rebateListenerMiddleware.middleware,
  fakeMgtBannerApi.middleware,
];
