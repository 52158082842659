import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'global-config';
import { BaseQueryType } from './types';

const baseQueryInterceptor: BaseQueryType = async (args, api, extraOptions) => {
  const baseUrl = config.apiUrl;
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      headers.set('Accept', 'application/json');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  });

  const result = await baseQuery(args, api, extraOptions);
  return result;
};

export const s3Api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.s3Url}/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  endpoints: () => ({}),
  reducerPath: 's3Api',
});
