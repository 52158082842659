import config from 'global-config';
import {
  call,
  put,
  takeLatest,
  delay,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { isMobile } from 'react-device-detect';
import { filter, get, take, find } from 'lodash';
import request from 'utils/request';
import dayjs from 'dayjs';

import * as actions from './actions';
import * as constants from './constants';
import {
  getSplashAdSession,
  setSplashAd,
  setSplashAdSession,
  showSplashAdBanner,
} from 'utils/localStorage';
import { SPLASH_ADS_LOCATION } from 'global-constant';
import { isGrantedPermission } from 'utils/helper';
import {
  showSplashAdModal,
  showNetPromotersScoreSurveyModal,
} from '../Modals/actions';

// call request token api
function* onLoadBannerSlider(action) {
  const requestURL = `${config.apiUrl}/landers-bannerslider/home`;
  try {
    const res = yield call(request, requestURL, { method: 'GET' });

    yield put(actions.loadBannerSliderSuccess(res));
  } catch (err) {
    yield put(actions.loadBannerSliderFailed(err));
  }
}

function* onLoadProducts(action) {
  const requestURL = `${config.apiUrl}/json-page/new-home`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });
    const bannerSlider = find(res, (item) => item.type === 'bannerSlider');
    yield put(
      actions.loadProductsSuccess(
        filter(res, (item) => item.type !== 'bannerSlider'),
      ),
    );
    yield put(
      actions.loadBannerSliderSuccess(get(bannerSlider, 'banner') || []),
    );
    yield put(actions.loadCategoriesCards());
  } catch (err) {
    yield put(actions.loadCategoriesCards());
    yield put(actions.loadProductsFailed(err));
  }
}

function* onLoadWidget(action) {
  const requestURL = `${config.apiUrl}/json-widget?hash=${action.hash}`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });
    yield put(actions.loadWidgetSuccess({ hash: action.hash, data: res[0] }));
  } catch (err) {
    yield put(actions.loadWidgetFailed(err));
  }
}

function* onLoadCategoriesCards(action) {
  const requestURL = `${config.apiUrl}/ld-products-card/categoriescards`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });

    yield delay(500);
    yield put(actions.loadCategoriesCardsSuccess(res));
  } catch (err) {
    yield put(actions.loadCategoriesCardsFailed(err));
  }
}

function* onLoadPastPurchaseProducts(action) {
  const requestURL = `${config.apiUrl}/get-last-past-purchase-cards`;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    });

    const firstLoaded = take(res, 2);
    yield put(actions.loadPastPurchaseProductsSuccess(firstLoaded));
    yield delay(1000);

    yield put(actions.loadPastPurchaseProductsSuccess(res));
  } catch (err) {
    yield put(actions.loadPastPurchaseProductsFailed(err));
  }
}

function* onGetSplashAd(action) {
  const showSplashAd = showSplashAdBanner();
  const session = getSplashAdSession();
  const location = action.payload?.location;

  const requestURL = `${config.apiUrl}/snapmart-splashad/splashad/list`;
  let splashAds = null;
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
      headers: {
        'Device-Type': isMobile ? 'm-site' : 'desktop',
      },
    });
    const isGranted = isGrantedPermission();

    if (location === SPLASH_ADS_LOCATION.ON_LAUNCH) {
      if (showSplashAd) {
        splashAds = res.items.find(
          (item) =>
            item.splash_ad_location.includes(action.payload.location) &&
            (!item.is_notify_only || !isGranted),
        );
        setSplashAd(dayjs().add(30, 'minute'));
      }
    } else {
      splashAds = res.items.find(
        (item) =>
          !item.splash_ad_location ||
          (item.splash_ad_location?.includes(action.payload.location) &&
            (!item.is_notify_only || !isGranted) &&
            (!item.is_session_only || !session)),
      );
    }

    if (!!splashAds) {
      if (
        action.payload?.location === SPLASH_ADS_LOCATION.SUCCESSFUL_PURCHASE
      ) {
        const timeDelay = +splashAds.time_delay || 0;
        yield delay(timeDelay);
      }

      if (!session && splashAds?.is_notify_only && splashAds?.is_session_only) {
        setSplashAdSession(true);
      }

      yield put(showSplashAdModal(splashAds));
    }
  } catch (err) {
    yield put(actions.getSplashAdFailed(err));
  }
}

function* onLoadNetPromotersScoreSurvey(action) {
  try {
    const npsData = action.payload.data;
    const surveyRequestURL = `${config.npsUrl}${npsData.url}?type=${npsData.type}&orderNumber=${npsData.orderNumber}`;
    const orderRequestURL = `${config.npsUrl}/nps/orders?orderNumber=${npsData.orderNumber}`;

    const surveyRes = yield call(request, surveyRequestURL, {
      method: 'GET',
    });

    const orderRes = yield call(request, orderRequestURL, {
      method: 'GET',
    });

    const survey = yield surveyRes;
    const order = yield orderRes;

    const npsPayload = {
      order: {
        orderNumber: npsData.orderNumber,
        orderDetail: order.data,
      },
      userID: npsData.userID,
      surveys: survey.data,
    };

    yield delay(100);

    yield put(showNetPromotersScoreSurveyModal(npsPayload));
    yield put(actions.loadNetPromotersScoreSurveySuccess(npsPayload));
  } catch (err) {
    yield put(actions.loadNetPromotersScoreSurveyFailed(err));
  }
}

export default function* homeSaga() {
  yield takeLatest(constants.LOAD_BANNER_SLIDER, onLoadBannerSlider);
  yield takeLatest(
    constants.LOAD_PAST_PURCHASE_PRODUCTS,
    onLoadPastPurchaseProducts,
  );
  yield takeLatest(constants.LOAD_PRODUCTS, onLoadProducts);
  yield takeLatest(constants.LOAD_CATEGORIES_CARDS, onLoadCategoriesCards);
  yield takeEvery(constants.LOAD_WIDGET, onLoadWidget);
  yield takeEvery(constants.GET_SPLASH_AD, onGetSplashAd);
  yield takeLatest(
    constants.LOAD_NET_PROMOTERS_SCORE_SURVEY,
    onLoadNetPromotersScoreSurvey,
  );
}
